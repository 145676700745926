import $ from 'jquery';
import prestashop from 'prestashop';


$(document).ready(() => {
    var i=0;
    var nbFichiers=1;			//variable pour limiter le nombre de fichiers à 8

    $('#btn_ajout_emplacement_fichier').click(function(){
        if(nbFichiers<8) {
            $("#pour_la_liste_de_fichiers").append("<div class='nouveau_fichier'><input type='file' name='newFileUpload[]' class='noUniform btn_ajout_fichier'><input type='button' class='btn_remove_file' value='X'></div>");

            $('.nouveau_fichier .btn_remove_file').click(function(){
                if(nbFichiers==8){
                    document.getElementById("btn_ajout_emplacement_fichier").style.display = "block";
                }
                $(this).parent().remove();
                nbFichiers--;
            });

            nbFichiers++;
            if(nbFichiers==8){
                document.getElementById("btn_ajout_emplacement_fichier").style.display = "none";
            }
        }
    });
});